import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import Btn from '@/components/Button/Btn.vue';
import FormWrap from '@/components/Elements/FormWrap.vue';
import PublicPageTitle from '@/components/Elements/PublicPageTitle.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import PasswordInput from '@/components/FormInputs/PasswordInput/PasswordInput.vue';
let Register = class Register extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            name: '',
            email: '',
            password: '',
            token: '',
        };
        this.errors = {
            name: [],
            email: [],
            password: [],
            token: [],
        };
        this.confirm_password = '';
        this.validPassword = false;
    }
    mounted() {
    }
    onPasswordConfirmChange() {
        this.validPassword = this.form.password === this.confirm_password;
    }
    async submitRegister() {
        try {
            // Execute reCAPTCHA with action "submitRegister".
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('lodgeEnquiry');
            if (!token || token === "") {
                throw new Error('reCAPTCHA failed');
            }
            this.form.token = token;
            await this.$store.dispatch('auth/register', this.form);
            await this.$router.push({ path: 'register/verify', query: { email: this.form.email, name: this.form.name } });
        }
        catch (e) {
            this.errors = e.data && e.data.messages ? e.data.messages : [];
        }
    }
};
__decorate([
    Watch('confirm_password')
], Register.prototype, "onPasswordConfirmChange", null);
Register = __decorate([
    Component({
        components: { Btn, FormWrap, PublicPageTitle, TextInput, PasswordInput },
        metaInfo() {
            return {
                title: 'Africa Geographic | Join',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/register` }],
            };
        },
    })
], Register);
export default Register;
